<template>
  <DashboardTemplate
    class="loading-fixe"
    v-loading="loading"
    element-loading-text="กำลังคำนวณขนาดธุรกิจ..."
    :fullscreen="true"
  >
    <el-row type="flex" justify="center" class="mg-y-3">
      <el-col :span="24">
        <CompanyInformation
          :ruleForm="ruleForm"
          :edit="true"
          :typePage="`empty`"
          @saveData="editProfile"
        />
      </el-col>
    </el-row>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import CompanyInformation from "@/components/Signup/CompanyInformation";
import { HTTP } from "@/service/axios";
export default {
  data() {
    return {
      ruleForm: {},
      originalImg: "",
      size: "",
      loading: false
    };
  },
  components: {
    DashboardTemplate,
    CompanyInformation
  },
  computed: {
    memberOrganizeOption() {
      return this.$store.state.memberOfOrganize;
    },
    memberOf() {
      let data = [];
      this.ruleForm.memberOf.map((value, index) => {
        if (value != 6) {
          let filter = this.$store.state.memberOfOrganize.filter(
            a => a.value == value
          );
          if (filter.length > 0) {
            data.push({ number: filter[0].value, etc: filter[0].label });
          }
        } else {
          data.push({ number: 6, etc: this.ruleForm.memberOfEtc });
        }
      });
      return data;
    },
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    }
  },
  mounted() {
    this.getUserById();
  },
  methods: {
    editProfile(e, change) {
      if (change) {
        this.loading = true;
      }

      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        }
      };
      let formData = new FormData();
      if (typeof this.$route.params.id != "undefined") {
        formData.append("id", this.ruleForm.id);
      }
      formData.append("userId", this.ruleForm.id);
      formData.append("profileImg", this.originalImg);
      formData.append(
        "file",
        typeof this.ruleForm.profileImg.raw != "undefined"
          ? this.ruleForm.profileImg.raw
          : ""
      );
      formData.append("email", this.ruleForm.email);
      formData.append(
        "password",
        typeof this.ruleForm.password != "undefined"
          ? this.ruleForm.password
          : ""
      );
      formData.append("titleName", this.ruleForm.titleName);
      formData.append("name", this.ruleForm.name);
      formData.append("surname", this.ruleForm.surname);
      formData.append("gender", this.ruleForm.gender);
      formData.append("businessSize", this.ruleForm.businessSize);
      formData.append("age", this.ruleForm.age);
      formData.append("education", this.ruleForm.education);
      formData.append("mobilephone", this.ruleForm.mobilephone);
      formData.append("companyName", this.ruleForm.companyName);
      formData.append("corporateNumber", this.ruleForm.corporateNumber);
      formData.append("position", this.ruleForm.position);
      formData.append("businessType", this.ruleForm.businessType);
      formData.append("cateIndustryType", this.ruleForm.cateIndustryType);
      formData.append("industryType", this.ruleForm.industryType);
      formData.append("industryTypeEtc", this.ruleForm.industryTypeEtc);
      formData.append("memberOf", JSON.stringify(this.memberOf));
      formData.append("memberOfEtc", this.ruleForm.memberOfEtc);
      formData.append("employment", this.ruleForm.employment);
      formData.append("yearEstablished", Number(this.ruleForm.yearEstablished));
      formData.append(
        "latestAnnualRevenues",
        this.ruleForm.latestAnnualRevenues
      );
      formData.append("latestAnnualProfits", this.ruleForm.latestAnnualProfits);
      formData.append("exportExperience", this.ruleForm.exportExperience);
      formData.append("exportRatio", this.ruleForm.exportRatio);
      formData.append("exportSales", this.ruleForm.exportSales);
      formData.append("orderConsistency", this.ruleForm.orderConsistency);
      formData.append("exportFrequency", this.ruleForm.exportFrequency);
      formData.append("exportCountry", this.ruleForm.exportCountry);
      formData.append(
        "trainingExpectations",
        this.ruleForm.trainingExpectations
      );
      formData.append(
        "moreExportExpectations",
        typeof this.ruleForm.moreExportExpectations != "undefined"
          ? this.ruleForm.moreExportExpectations
          : ""
      );
      formData.append("expectedPercentage", this.ruleForm.expectedPercentage);
      formData.append(
        "expectedPercentageEtc",
        this.ruleForm.expectedPercentageEtc
      );
      formData.append("consent", this.ruleForm.consent);
      formData.append("_csrf", this.$csrfToken);
      formData.append("operatorType", this.ruleForm.operatorType);
      formData.append("exportPlan", this.ruleForm.exportPlan);
      formData.append("isProduction", this.ruleForm.isProduction);
      formData.append("shipment", this.ruleForm.shipment);
      formData.append("paymentTerm", this.ruleForm.paymentTerm);
      formData.append("exportValue", this.ruleForm.exportValue);

      let url = "user";
      if (typeof this.$route.params.id != "undefined") {
        url = `company/information`;
      }

      HTTP.put(url, formData, config)
        .then(res => {
          if (res.data.success) {
            this.loading = false;
            this.$message({
              message: "บันทึกข้อมูลสำเร็จ",
              type: "success"
            });
            this.getUserById(true);
          } else {
            this.loading = false;
            this.$message.error(res.data.data);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log(`put/user error`, e);
          this.loading = false;
        });
    },
    getUserById ( edit = "" )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let url = "user";

      if (typeof this.$route.params.id != "undefined") {
        url = `/information/${this.$route.params.id}`;
      }
      HTTP.get(url)
        .then(res => {
          if (res.data.success) {
            //console.log(url, res);
            this.ruleForm = JSON.parse(JSON.stringify(res.data.obj));
            this.ruleForm.memberOf = [];

            res.data.obj.memberOf.map(data => {
              this.ruleForm.memberOf.push(data.memberOf);
              if (data.memberOf == 6) {
                this.ruleForm.memberOfEtc = data.memberOfEtc;
              } else if (data.memberOf == 7) {
                this.ruleForm.memberOfEtc = "EXAC";
              }
            });
            //สำหรับ user เก่าส่งค่า null จะ error 500
            // res.data.obj.industryType == null
            //   ? (this.ruleForm.industryType = 0)
            //   : null;
            // res.data.obj.cateIndustryType == null
            //   ? (this.ruleForm.cateIndustryType = 0)
            //   : null;
            // res.data.obj.exportRatio == null
            //   ? (this.ruleForm.exportRatio = 0)
            //   : null;
            // res.data.obj.exportSales == null
            //   ? (this.ruleForm.exportSales = 0)
            //   : null;
            // res.data.obj.orderConsistency == null
            //   ? (this.ruleForm.orderConsistency = 0)
            //   : null;
            // res.data.obj.exportFrequency == null
            //   ? (this.ruleForm.exportFrequency = 0)
            //   : null;
            // res.data.obj.exportCountry == null
            //   ? (this.ruleForm.exportCountry = 0)
            //   : null;
            // res.data.obj.trainingExpectations == null
            //   ? (this.ruleForm.trainingExpectations = 0)
            //   : null;
            // res.data.obj.moreExportExpectations == null
            //   ? (this.ruleForm.moreExportExpectations = 0)
            //   : null;
            // res.data.obj.expectedPercentageEtc == null
            //   ? (this.ruleForm.expectedPercentageEtc = 0)
            //   : null;

            // res.data.obj.operatorType == null
            //   ? (this.ruleForm.operatorType = 0)
            //   : null;
            // res.data.obj.exportPlan == null
            //   ? (this.ruleForm.exportPlan = 0)
            //   : null;
            // res.data.obj.shipment == null ? (this.ruleForm.shipment = 0) : null;
            // res.data.obj.paymentTerm == null
            //   ? (this.ruleForm.paymentTerm = 0)
            //   : null;
            // res.data.obj.exportValue == null
            //   ? (this.ruleForm.exportValue = 0)
            //   : null;

            if (typeof this.$route.params.id != "undefined") {
              this.ruleForm.role = 0;
            } else {
              this.originalImg = res.data.obj.profileImg;
              this.$store.commit(
                "SET_USER",
                JSON.parse(JSON.stringify(res.data.obj))
              );
            }
            this.originalImg = res.data.obj.profileImg;
            this.checkDisable();
            if (edit) {
              if (typeof this.$route.params.id != "undefined") {
                // this.$router.push("/user/list");
              } else if (
                res.data.obj.recommendStatus == 0 ||
                res.data.obj.recommendStatus == 2
              ) {
                this.$router.push("/factor");
              } else {
                this.$router.push("/result");
              }
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchUserList error", e);
        });
    },
    checkDisable() {
      let index = this.ruleForm.memberOf.findIndex(a => a == 7);
      let check = this.ruleForm.memberOf.findIndex(a => a != 7);
      if (index > -1) {
        let obj = this.memberOrganizeOption;
        obj.map(data => {
          data.value != 7 ? (data.disable = true) : null;
        });
        this.$store.commit("SET_MEMBEROF", obj);
      } else if (check > -1) {
        let obj = this.memberOrganizeOption;
        obj.map(data => {
          data.value == 7 ? (data.disable = true) : false;
        });
        this.$store.commit("SET_MEMBEROF", obj);
      } else {
        let obj = this.memberOrganizeOption;
        obj.map(data => {
          data.disable = false;
        });
        this.$store.commit("SET_MEMBEROF", obj);
      }
    }
  }
};
</script>
